import { useState, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import moment from "moment";
import { ReactComponent as CloseBtn } from "../../../assets/icons/Icon.svg";
import { Box } from "@mui/material";
import { WORKERS_MODAL_ACTION_TYPE } from "../../../redux/actions-type";
import { ValidationSchema } from "./components/ValidationSchema/ValidationSchema";
import SubmitBtn from "./components/Buttons/SubmitBtn";
import InputField from "./components/Inputs/InputField";
import DeleteItemModal from "../DeleteItemModal/DeleteItemModal";
import { deleteWorkerAction } from "../../../redux/actions/workerActions";
import ProfileList from "./components/Selection/ProfileList";
import PositionList from "./components/Selection/Position/PositionList";

const WorkerModal = () => {
  const dispatch = useDispatch();
  const { workersModalData } = useSelector((state) => state.workersModal);
  const { workerSearchValues } = useSelector((state) => state.searchValues);
  const { workers, lastPage } = useSelector((state) => state.workersPagination);

  console.log(workersModalData, "worker modal dataaa");

  const inputNameArr1 = [
    "fullName",
    "email",
    "password",
    "number",
    "department",
  ];
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const updateModalState = (keyName, value) => {
    setInputValue(keyName, value);

    dispatch({
      type: WORKERS_MODAL_ACTION_TYPE.GET_WORKERS_MODAL,
      payload: {
        data: { ...workersModalData, [keyName]: value },
        openModal: true,
      },
    });
  };

  // formik
  const formik = useFormik({
    initialValues: {
      fullName: workersModalData.fullName ? workersModalData.fullName : "",
      email: workersModalData.email ? workersModalData.email : "",
      password: workersModalData.password ? workersModalData.password : "",
      number: workersModalData.number ? workersModalData.number : "",
      department: workersModalData.department
        ? workersModalData.department
        : "",
      // position: workersModalData.position ? workersModalData.position : "",
    },
    validationSchema: ValidationSchema,
  });
  const setInputValue = useCallback(
    (key, value) =>
      formik.setValues({
        ...formik.values,
        [key]: value,
      }),
    [formik]
  );

  const closeModal = () => {
    dispatch({
      type: WORKERS_MODAL_ACTION_TYPE.GET_WORKERS_MODAL,
      payload: { data: {}, openModal: false },
    });
  };

  const deleteItem = () => {
    const pageNumber =
      lastPage > 1 ? (workers.length > 1 ? lastPage : lastPage - 1) : 1;
    const _id = workersModalData._id;
    const searchQuery = workerSearchValues;
    dispatch(deleteWorkerAction(_id, pageNumber, searchQuery));
    closeModal();
  };

  return (
    <div className="create-update-modal-con teacher-modal">
      <div className="create-update-modal">
        <div className="create-update-modal-head">
          <h2>{workersModalData?._id ? "Əməkdaş yenilə" : "Əməkdaş yarat"}</h2>
          <CloseBtn onClick={closeModal} />
        </div>

        <Box
          onSubmit={(e) => e.preventDefault()}
          component="form"
          sx={{
            width: 500,
            maxWidth: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <div className="create-update-modal-form">
            {inputNameArr1.map((name, index) => (
              <InputField
                key={index}
                inputName={name}
                formik={formik}
                setInputValue={setInputValue}
                workersModalData={workersModalData}
              />
            ))}
            <PositionList
              modalData={workersModalData}
              updateModalState={updateModalState}
              formik={formik}
            />
            <ProfileList
              formik={formik}
              updateModalState={updateModalState}
              modalData={workersModalData}
            />
          </div>
        </Box>

        {workersModalData?._id ? (
          <SubmitBtn
            formik={formik}
            workersModalData={workersModalData}
            funcType="update"
            setShowDeleteModal={setShowDeleteModal}
          />
        ) : (
          <SubmitBtn
            formik={formik}
            workersModalData={workersModalData}
            funcType="create"
            setShowDeleteModal={setShowDeleteModal}
          />
        )}

        {workersModalData?._id && (
          <div className="joined-time">
            Qoşuldu: {moment(workersModalData.createdAt).format("YYYY.MM.DD")}
          </div>
        )}
        {showDeleteModal && (
          <DeleteItemModal
            setShowDeleteModal={setShowDeleteModal}
            deleteItem={deleteItem}
          />
        )}
      </div>
    </div>
  );
};

export default WorkerModal;
