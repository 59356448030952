import { useDispatch, useSelector } from "react-redux";
import {
  WEEKS_BETWEEN_SELECTED_DATES_ACTION_TYPE,
  MAIN_PAGE_TYPE_ACTION_TYPE,
  TABLE_TYPE_ACTION_TYPE,
  MODAL_LESSON_ACTION_TYPE,
  SHOWNAV_ACTION_TYPE,
  DROPDOWN_ERROR_TYPE,
} from "../../redux/actions-type";
import { getFinanceChartAction } from "../../redux/actions/financeAction";
import { getFinanceDataAction } from "../../redux/actions/financeAction";
import moment from "moment";
import { ReactComponent as DashboardIcon } from "../../assets/icons/dashboardIcon.svg";
import { ReactComponent as CoursesIcon } from "../../assets/icons/coursesIcon.svg";
import { ReactComponent as TeachersIcon } from "../../assets/icons/teachersIcon.svg";
import { ReactComponent as StudentsIcon } from "../../assets/icons/studentsIcon.svg";
import { ReactComponent as TableIcon } from "../../assets/icons/tableIcon.svg";
import { ReactComponent as AdminIcon } from "../../assets/icons/sidebar/users-01.svg";
import { ReactComponent as SyllabusIcon } from "../../assets/icons/sidebar/syllabus-svgrepo-com.svg";
import { ReactComponent as MainPanelIcon } from "../../assets/icons/mainPanelIcon.svg";
import { ReactComponent as SalaryIcon } from "../../assets/icons/salaryIcon.svg";
import { ReactComponent as ExpensesIcon } from "../../assets/icons/expensenIcon.svg";
import { ReactComponent as IncomesIcon } from "../../assets/icons/incomesIcon.svg";
import { ReactComponent as FeedBacksIcon } from "../../assets/icons/sidebar/feedbacks-icon.svg";

export function useCustomHook() {
  const dispatch = useDispatch();
  const { financeMonthsFilter, financeChooseDate } = useSelector(
    (state) => state.financeDateFilter
  );
  const startWeek = new Date();
  startWeek.setDate(
    startWeek.getDate() -
      (startWeek.getDay() === 0 ? 7 : startWeek.getDay()) +
      1
  );
  startWeek.setHours(0, 0, 0, 0);
  const endWeek = new Date();
  endWeek.setDate(startWeek.getDate() + 6);
  endWeek.setHours(0, 0, 0, 0);
  const weeksArr = ["", "B.e", "Ç.a", "Ç.", "C.a", "C.", "Ş.", "B."];
  const lessonHours = [
    {
      first_time: "08:30",
      second_time: "10:00",
    },
    {
      first_time: "10:00",
      second_time: "11:30",
    },
    {
      first_time: "11:30",
      second_time: "13:00",
    },
    {
      first_time: "13:00",
      second_time: "14:30",
    },
    {
      first_time: "14:30",
      second_time: "16:00",
    },
    {
      first_time: "16:00",
      second_time: "17:30",
    },
    {
      first_time: "17:30",
      second_time: "19:00",
    },
    {
      first_time: "19:30",
      second_time: "20:00",
    },
  ];

  const fineTypeList = [
    { name: "Şifahi xəbərdarlıq", key: "verbalWarning" },
    { name: "Yazılı xəbərdarlıq", key: "writtenWarning" },
    { name: "Töhmət", key: "rebuke" },
    { name: "Şiddətli töhmət", key: "severeRebuke" },
  ];

  const generalProfileList = [
    {
      id: 1,
      name: "İdarəetmə paneli",
      key: "dashboard",
      path: "dashboard",
      icon: <DashboardIcon />,
    },
    {
      id: 2,
      name: "Davamiyyət",
      key: "attendanceTable",
      path: "student",
      icon: <MainPanelIcon />,
    },
    {
      id: 3,
      name: "Fənlər",
      key: "courses",
      path: "courses",
      icon: <CoursesIcon />,
    },
    {
      id: 4,
      name: "Sillabus",
      key: "syllabus",
      path: "syllabus",
      icon: <SyllabusIcon />,
    },
    {
      id: 5,
      name: "Müəllimlər",
      key: "teachers",
      path: "teachers",
      icon: <TeachersIcon />,
    },
    {
      id: 6,
      name: "Əməkdaşlar",
      key: "workers",
      path: "workers",
      icon: <AdminIcon />,
    },
    {
      id: 7,
      name: "Tələbələr",
      key: "students",
      path: "students",
      icon: <StudentsIcon />,
    },
    {
      id: 8,
      name: "Cədvəl",
      key: "lessonTable",
      path: "table",
      icon: <TableIcon />,
    },

    {
      id: 9,
      name: "Əmək haqqı",
      key: "salary",
      path: "salary",
      icon: <SalaryIcon />,
    },
    {
      id: 10,
      name: "Alınacaqlar cədvəli",
      key: "receipts",
      path: "receipts",
      icon: <ExpensesIcon />,
    },
    {
      id: 11,
      name: "Maliyyə",
      key: "finance",
      path: "finance",
      icon: <ExpensesIcon />,
    },
    {
      id: 12,
      name: "Həvəsləndirmə",
      key: "stimulation",
      path: "stimulations",
      icon: <IncomesIcon />,
    },
    {
      id: 13,
      name: "Rəy",
      key: "feedback",
      path: "feedback",
      icon: <FeedBacksIcon />,
    },
    {
      id: 14,
      name: "Admin",
      key: "admin",
      path: "admins",
      icon: <AdminIcon />,
    },
    {
      id: 15,
      name: "Müvəqqəti cədvəl",
      key: "temporaryTable",
      path: "temporary-table",
      icon: <TableIcon />,
    },
    { id: 16, name: "Demo", key: "demo", path: "demo", icon: <TableIcon /> },
  ];

  const workerPositionList = [
    { name: "Maliyyə Hesablayıcısı", key: "accounting-officer" },
  ];

  const generalProfilePowerList = [
    { name: "Tam-səlahiyyətli", key: "all" },
    { name: "Yarım-səlahiyyətli", key: "update" },
    { name: "Heç biri", key: "only-show" },
  ];

  const changeShowNav = (value) => {
    dispatch({ type: SHOWNAV_ACTION_TYPE.SHOW, payload: value });
  };
  const getWeeksBetweenDates = (start, end) => {
    let weeksList = [];
    const startDate = new Date(start);
    const endDate = new Date(end);
    let startWeek = new Date(startDate);
    let endWeek = new Date(startDate);

    if (endWeek.getDay() > 0) {
      endWeek.setDate(startDate.getDate() + (7 - startDate.getDay()));
    }

    const lastWeekEndDay = new Date(endDate);

    if (lastWeekEndDay.getDay() > 0) {
      lastWeekEndDay.setDate(
        lastWeekEndDay.getDate() + (7 - lastWeekEndDay.getDay())
      );
    }

    lastWeekEndDay.setDate(lastWeekEndDay.getDate() + 1);

    while (lastWeekEndDay > endWeek) {
      weeksList.push({
        startWeek: startWeek.toString(),
        endWeek: endWeek.toString(),
        allWeekDays: {
          monday: new Date(
            new Date(endWeek).setDate(endWeek.getDate() - 6)
          ).toString(),
          tuesday: new Date(
            new Date(endWeek).setDate(endWeek.getDate() - 5)
          ).toString(),
          wednesday: new Date(
            new Date(endWeek).setDate(endWeek.getDate() - 4)
          ).toString(),
          thursday: new Date(
            new Date(endWeek).setDate(endWeek.getDate() - 3)
          ).toString(),
          friday: new Date(
            new Date(endWeek).setDate(endWeek.getDate() - 2)
          ).toString(),
          saturday: new Date(
            new Date(endWeek).setDate(endWeek.getDate() - 1)
          ).toString(),
          sunday: endWeek.toString(),
        },
      });

      if (startWeek.getDay() === 0) {
        startWeek.setDate(startWeek.getDate() + 1);
      } else {
        startWeek.setDate(startWeek.getDate() + (8 - startWeek.getDay()));
      }

      endWeek.setDate(endWeek.getDate() + 7);
    }

    weeksList.at(-1).endWeek = endDate.toString();

    dispatch({
      type: WEEKS_BETWEEN_SELECTED_DATES_ACTION_TYPE.GET_SELECTED_DATES,
      payload: weeksList,
    });
  };
  const changeMainPageType = (type) => {
    dispatch({
      type: MAIN_PAGE_TYPE_ACTION_TYPE.GET_MAIN_PAGE_TYPE,
      payload: type,
    });
  };
  const changeTableType = (type) => {
    dispatch({ type: TABLE_TYPE_ACTION_TYPE.GET_TABLE_TYPE, payload: type });
  };
  const createLessonModal = (data) => {
    dispatch({
      type: MODAL_LESSON_ACTION_TYPE.SET_MODAL_LESSON,
      payload: data,
    });
  };
  const clearLessonModal = () => {
    dispatch({
      type: MODAL_LESSON_ACTION_TYPE.SET_MODAL_LESSON,
      payload: { modalLesson: {}, openModal: false },
    });
  };
  const changeDropdownNameErr = (value) => {
    dispatch({ type: DROPDOWN_ERROR_TYPE.GET_DROPDOWN_ERROR, payload: value });
  };
  const getFinanceDataAfterCreate = () => {
    if (financeChooseDate?.startDate && financeChooseDate?.endDate) {
      const start = moment(financeChooseDate?.startDate).format("YYYY-MM");
      const end = moment(financeChooseDate?.endDate).format("YYYY-MM");
      dispatch(getFinanceChartAction(start, end, ""));
      dispatch(getFinanceDataAction(start, end, ""));
    } else if (financeMonthsFilter) {
      dispatch(getFinanceDataAction("", "", financeMonthsFilter));
      if (financeMonthsFilter === 1) {
        dispatch(getFinanceChartAction("", "", 3));
      } else {
        dispatch(getFinanceChartAction("", "", financeMonthsFilter));
      }
    } else {
      dispatch(getFinanceChartAction("", "", 3));
      dispatch(getFinanceDataAction("", "", 1));
    }
  };

  return {
    startWeek,
    endWeek,
    lessonHours,
    weeksArr,
    fineTypeList,
    generalProfileList,
    generalProfilePowerList,
    workerPositionList,
    changeShowNav,
    getWeeksBetweenDates,
    changeMainPageType,
    changeTableType,
    createLessonModal,
    clearLessonModal,
    changeDropdownNameErr,
    getFinanceDataAfterCreate,
  };
}
