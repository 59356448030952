import React from "react";
import moment from "moment";
import "moment/locale/az";

const DemoMoreModal = ({ data }) => {

  const demoStatusList = [
    { key: "held", label: "Keçirilib" },
    { key: "notHeld", label: "Keçirilməyib" },
    { key: "confirmed", label: "Təsdiqlənib" },
    { key: "cancelled", label: "Ləğv edilib" },
  ];


  const status=demoStatusList.find(item=>item?.key===data.status).label;
  return (
    <>
      <div className="more-modal-header-inform">
        <h3>
          Ad soyad: <span>{data?.fullName}</span>
        </h3>
        <h3>
          Valideyn adı: <span>{data?.parentName}</span>
        </h3>
        <h3>
          Tələbə yaşı : <span>{data?.age}</span>
        </h3>
        <h3>
          Tədris dili : <span>{data?.sector}</span>
        </h3>
        <h3>
          Telefon nömrəsi : <span>{data?.phone}</span>
        </h3>
        <h3>
            Fənn:<span>{data?.class}</span>
          </h3>
        <h3>
          Tarix:{" "}
          <span>
            {data?.date
              ? moment(data.date).locale("az").format("DD MMMM YYYY")
              : ""}
          </span>
        </h3>
        <h3>
          Saat : <span>{data?.time}</span>
        </h3>

        <h3>
          Status : <span>{status}</span>
        </h3>
      </div>
    </>
  );
};

export default DemoMoreModal;
