import { useDispatch, useSelector } from "react-redux";
import {
  updateReceiptAction,
  createReceiptAction,
} from "../../../../../redux/actions/receiptsAction";
import { SEARCH_VALUES_ACTION_TYPES } from "../../../../../redux/actions-type";
import LoadingBtn from "../../../../Loading/components/LoadingBtn/LoadingBtn";
import { useEffect, useState } from "react";

const SubmitBtn = ({ receiptsModalData, funcType, formik }) => {
  const dispatch = useDispatch();
  const { receiptsModalLoading } = useSelector((state) => state.receiptsModal);
  const { user } = useSelector((state) => state.user);

  const [isDisabled, setIsDisabled] = useState(() => {
    if (funcType === "update") {
      return false;
    } else {
      return true;
    }
  });

  useEffect(() => {
    setIsDisabled(() => {
      if (funcType === "update") {
        if (
          user.role !== "super-admin" &&
          receiptsModalData?.status === "confirmed"
        )
          return true;
        if (Object.keys(formik.errors).length === 0) {
          return false;
        } else {
          return true;
        }
      } else {
        if (formik.isValid) {
          return false;
        } else {
          return true;
        }
      }
    });
  }, [formik.errors]);

  const receiptCreate = () => {
    dispatch({
      type: SEARCH_VALUES_ACTION_TYPES.RECEIPT_SEARCH_VALUE,
      payload: "",
    });
    if (receiptsModalData?._id) {
      dispatch(updateReceiptAction(receiptsModalData?._id, receiptsModalData));
    } else {
      dispatch({
        type: SEARCH_VALUES_ACTION_TYPES.RECEIPT_SEARCH_VALUE,
        payload: "",
      });
      dispatch(
        createReceiptAction({
          ...receiptsModalData,
        })
      );
    }
  };

  return (
    <div>
      <div className="create-update-modal-btn">
        <button
          disabled={isDisabled || receiptsModalLoading}
          onClick={receiptCreate}
        >
          {receiptsModalLoading ? (
            <LoadingBtn />
          ) : funcType === "update" ? (
            "Yenilə"
          ) : (
            "Yarat"
          )}
        </button>
      </div>
    </div>
  );
};

export default SubmitBtn;
