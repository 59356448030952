import React, { useEffect, useState } from "react";
import DemoCard from "./DemoCard";
import { useSelector } from "react-redux";
import Loading from "../../../globalComponents/Loading/Loading";
import { Pagination } from "antd";

const DemoData = ({ getPageNumber, demoPageNum }) => {
  const [openMoreModal, setOpenMoreModal] = useState(false);
  const { demos, totalPages, loading } = useSelector(
    (state) => state.demosPagination
  );

  useEffect(() => {
    if (openMoreModal) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "overlay";
    }
  }, [openMoreModal]);

  const tableHead = [
    { id: 1, label: "Tələbə adı" },
    { id: 2, label: "Tələbə yaşı" },
    { id: 3, label: "Tədris dili" },
    { id: 4, label: "Fənn" },
    { id: 11, label: "Müəllim adı" },
    { id: 5, label: "Valideyn adı" },
    { id: 6, label: "Telefon nömrəsi" },
    { id: 7, label: "Tarix" },
    { id: 8, label: "Saat" },
    { id: 9, label: "Status" },
    { id: 10, label: "" },
    // { id: 11, label: "" },
  ];
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          {/* {openMoreModal && (
            <MoreModal setOpenMoreModal={setOpenMoreModal} type="demo" />
          )} */}
          <div className="demo-table-scroll">
            <table className="details-table demo-table">
              <thead>
                <tr>
                  {tableHead.map((head, i) => (
                    <th key={i}>{head.label}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {demos?.map((demo, i) => (
                  <DemoCard
                    key={i}
                    data={demo}
                    mode="desktop"
                    setOpenMoreModal={setOpenMoreModal}
                    cellNumber={i + 1 + (demoPageNum - 1) * 10}
                  />
                ))}
              </tbody>
            </table>
          </div>

          <div className="details-list-tablet with-more">
            {demos?.map((teacher, i) => (
              <DemoCard
                key={i}
                data={teacher}
                mode="tablet"
                setOpenMoreModal={setOpenMoreModal}
                cellNumber={i + 1 + (demoPageNum - 1) * 10}
              />
            ))}
          </div>
          {totalPages > 1 && (
            <div className="pages-pagination">
              <Pagination
                current={demoPageNum}
                defaultCurrent={1}
                total={totalPages * 10}
                onChange={getPageNumber}
              />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default DemoData;
